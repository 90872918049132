<template>
	<div class="comnent">
		<div class="comh1">亿岩仓储服务协议</div> 
		<div class="time">更新日期：{{time}}</div>
		<span>亿岩仓储平台及“亿岩仓储App"（以下简称“亿岩仓储"） 是由山西海云启辰科技有限公司 （以下简称“本公司"）所有，用于提供煤炭行业的煤种信息查询匹配服务等技术平台及应用。</span>
		<p>《亿岩仓储服务协议》（以下简称”本协议"） 双方为本公司与亿岩仓储注册用户（以下简称“用户"或"您")，本协议具有法律效力。用户在使用亿岩仓储之前，应当认真阅读。当您点击"已阅读"按钮后，即表示您已阅读井充分理解本协议的全部内容，与亿岩仓储达成一致，成为亿岩仓储平台的用户，并自愿遵守本协议。</p>
		<div class="title">一、接受条款</div>
			<p>1.1用户点击亿岩仓储注册页面的同意注册按钮并完成注册程序、获得亿岩仓储账号和密码时，即表示用户完全理解并接受本协议项下的全部条款，同意将其作为确定双方权利义务的依据。如果用户不同意本协议的任意内容，或者无法准确理解本公司对条款的解释，请不要进行后续操作，包括但不限于不要接受本协议，不使用本服务。如果用户对本协议的条款有疑问，请通过本公司客服渠道进行询问(客服电话为021-58770005)，本公司将向您解释条款内容。</p>
			<p>1.2本协议内容中以加粗等方式显著标识的条款，请用户着重阅读。</p>
			<p>1.3用户同意，如本公司需要对本协议进行变更或修改的，须通过网站 (www.zhaomei.com）公告的方式提前予以公布，公告期限届满后即时生效；若用户在本协议内容公告变更生效后继续使用亿岩仓储服务的，表示用户已充分阅读、理解井接受变更后的协议内容，也将遵循变更后的协议内容使用亿岩仓储服务；若用户不同意变更后的协议内容，用户应在变更生效前停止使用亿岩仓储服务。</p>
        <div class="title">二、用户注册</div>
            <p>2.1用户确认，在完成注册程序或以其他本公司允许的方式使用亿岩仓储时，用户应当是具备完全民事行为能力和权利能力的自然人、法人或其他组织；否则，用户应在其法定监护人指导下方能使用亿岩仓储，井由用户及其监护人对亿岩仓储上的各项行为承担全部责任。</p>
			<p>2.2用户在注册程序中，应当依照法律法规及本公司的要求，根据亿岩仓储相应页面的提示淮确填写、上传各项用户资料，并在资料信息变更5日内及时进行用户信息更新，以确保用户提交的信息真实、准确、完整。若因用户未及时更新资料，导致有关流程和操作（包括但不限于后续资金流、信息流等作业） 无法完成或发生错误，由此产生的一切后果和责任由用户承担，用户不得以此作为取消交易、拒绝付款或采取其他不当行为的理由。</p>
            <p>2.3用户应通过注册设置的登录账号和密码登陆亿岩仓储，道守亿岩仓储或服务项目的相关服务协议及使用守则，且自行负责对登录账号及密码的保管及保密，用户对在该登录账号及密码下发生的所有行为承担责任。用户应以正确的步骤离开亿岩仓储，旦发现任何人未经授权使用其用户名和密码，应立即通知本公司并提供相应的证明。本公司将在合理时间内根据审查结果做出应对措施，在采取相应措施之前，用户应对已经产生的后果、损失自行承担责任。</p>
        <div class="title">三、用户享受的服务</div>
            <p>3.1亿岩仓储用户可以享受如下服务：</p>
            <p>3.1.1亿岩仓储账户：指在用户使用本服务时，由亿岩仓储提供的唯一的账号，对用户进行身份识别。用户通过登录完成各种操作，井可设置或修改登录密</p>
            <p>3.1.2信息的发布、搜索、匹配服务。服务包括但不限于行业信息搜素、流程监控、库存管理、需求匹配等综合服务。</p>
            <p>3.2收费：本公司保留在根据第1条通知您后，收取服务费用的权利。用户因进行交易、向本公司获取有偿服务或接触本公司服务器而发生的所有应纳税賦，以及相关硬件、软件、通讯、网络服务及其他方面的费用均由您自行承担。</p>
            <p>3.3用户在使用亿岩仓储的交易过程中发生纠纷，交易双方应友好协商解决。如任何一方将交易纠纷提交亿岩仓储要求调解处理，则本公司作为独立第三方，有权根据交易规则、交易惯例单方判断做出调解处理决定，井向权利人提供相关的用户信息，用户了解井同意接受本公司的判断和决定，同意接受本公司因此采取的任何措施井不因任何原因追究本公司的责任。</p>
        <div class="title">四、用户的义务</div>
            <p>4.1用户在使用亿岩仓储服务时，应自行判断交易对方是否具有完全民事行为能力井自行决定是否使用亿岩仓储服务与对方进行交易，且用户应自行承担与此相关的所有风险。</p>
            <p>4.2用户确认，在亿岩仓储上发生的所有业务，已经不可撤销的授权亿岩仓储按照其指定的相关规则进行处理，包括但不限于信息搜索、流程监控管理、库存管理等；同时用户不可撒销的授权本公司按照指令将业务涉及款项的全部或部分支付给交易一方或双方；并且本公司有权从亿岩仓储获取用户的相关信息（包括但不限于行为信息、账户相关信息等）。亿岩仓储按照用户的指令进行上述操作完全来自手用户的授权，公公司对此没有在每次操作时进行确认或提醒的义务，同时对因此给您造成的任何损失均不承担责任。但您确认，您使用亿岩仓储服务时，您仍应完全遵守本协议及本公司制定的各项规则及页面提示内容等。</p>
            <p>4.3用户应当遵守有关法律法规，按照相关协议使用规则享受本公司提供的服务。不得从事以下行为：</p>
            <p>1）申请或者变更用户信息时提供虛假信息;</p>
            <p>2）盗用他人信息；</p>
            <p>3）利用任何方式方法危害亿岩仓储网站或系统的安全;</p>
            <p>4）为任何非法目的使用亿岩仓储；</p>
            <p>5）为任何非法目的使用亿岩仓储；在亿岩仓储网站或系统上复制、发布任何形式的虛假信息，或复制、发布含有下列内容的信息：</p>
            <p>a)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一</p>
            <p>b)损害国家荣誉和利益；</p>
            <p>c)煽动民族仇恨、民族歧视，破坏民族团结;</p>
            <p>d)破坏国家宗教政策，宣扬邪教和封建迷信；</p>
            <p>e)散布谣言，扰乱社会秩序，破坏社会稳定；</p>
            <p>f)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪；</p>
            <p>g)含有法律、行政法规禁止的其他内容；</p>
            <p>h)其他法律法规、本协议、亿岩仓储各项规则和要求禁止从事的行为。</p>
            <p>4.4用户违反上述规定，因此产生的任何法律后果，用户应独立承担所有的法律责任。如造成亿岩仓储、本公司产生任何损失或增加费用，本公司將有权进行追偿。同时，用户同意本公司将有权采取以下措施，维护交易秩序和交易安全：</p>
            <p>1）关闭涉嫌恶意交易、欺诈交易、虛假交易的相应交易运单；</p>
            <p>2）删除涉嫌违反法律法规规定、本协议或亿岩仓储规则的信息；</p>
            <p>3）用户信用降级、考核不合格；</p>
            <p>4）公示用户涉嫌违法、违约行为井公布亿岩仓储采取的措施；</p>
            <p>5）中止、终止提供部分或全部服务；</p>
            <p>6）向司法机关、工商行政管理部门等国家机关举报并提供相关的用户信息；</p>
        <div class="title">五、电子签名免责协议</div>
            <p>5.1用户在发生业务的时候需向亿岩仓储平台提供电子签名，用于签署与自己相关的合同及票据，电子签名的合同及票据具备法律效力，所产生一切后果由签名双方全部承担。</p>
            <p>5.2用户有权监督熊猫煤煤仓平台正确使用电子签名的权利。</p>
            <p>5.3亿岩仓储有权要求用户在亿岩仓储平台接单从事货物运输需提供电子签名，井授权亿岩仓储平台使用电子签名签署合同及电子票据，以保障承运双方的合法利益。</p>
        <div class="title">六、用户信息的保护</div>
            <p>6.1用户信息的保护：</p>
            <p>保护用户信息（即能够独立或与其他信息结合后识别用户身份的信息）是亿岩仓储的一项原则，亿岩仓储非常重视用户信息的保护，在您使用亿岩仓储提供的服务时，您同意亿岩仓储按照在亿岩仓储公布的隐私政策收集、存储、使用、披露和保护您的用户信息。亿岩仓储希望通过隐私政策向您清楚地介绍亿岩仓储对您用户信息的处理方式，因此亿岩仓储建议您完整地阅读隐私政策，以帮助您更好地保护您的隐私</p>
            <p>6.2非用户信息的保证与授权</p>
            <p>6.2.1您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，亿岩仓储可对您发布的信息依法或依本协议进行删除或屏蔽。</p>
            <p>6.2.2 为方便您使用亿岩仓储其他相关服务，您授权亿岩仓储将您在账户注册和使用亿岩仓储服务过程中提供、形成的信息传递给亿岩仓储等其他相关服务提供者，，或从亿岩仓储等其他相关服务提供者获取您在注册、使用相关服务期间提供、形成的信息。</p>
        <div class="title">七、信息声明</div>
            <p>亿岩仓储上的煤矿价格、数量、是否有货、指标、参考价等信息将根据市场行情及信息来源随时发生变动，亿岩仓储不作特别通知。亿岩仓储会尽最大努力保证您所浏览商品信息的准确性，但由于网络及电脑终端兼容性等客观原因存在，网站网页品示的信息可能会有一定的滞后性或差错，对此情形请您知悉井理解；亿岩仓储欢迎纠错，井会视情况给予纠錯者一定的奖励。</p>
        <div class="title">八、违约处理</div>
            <p>如果发现您违反本协议约定的，亿岩仓储有权独立判断井视情节不经通知随时采取警告、拒绝发布、立即对相关内容进行删除、屏蔽，限制或禁止使用部分或全部功能、账号封禁直至注销的处罚，并公告处理结果，井有权根据实际情况决定是否恢复相关账号的使用。对涉嫌违法、犯罪的行为将保存相关记录，井依法向有关部门报告、配合调查。</p>
            <p>如在信息摄合使用过程中发生纠纷，买卖双方在当事人自愿平等的前提下，买卖双方可提出要求"亿岩仓储”协助调解。“亿岩仓储"会在查明事实、分清是非的基础上，严格遵守国家法律法规来给出建议。经调解达成的协议具有法律效力。</p>
        <div class="title">九、协议更新与终止</div>
            <p>9.1协议的更新</p>
            <p>9.1.1 根据国家法律法规变化及网站运营需要，亿岩仓储有权对本协议条款不时地进行修改，修改内容将至少在正式实施前七日予以公示，并征求意见，更新后的协议自发布之日即时效。</p>
            <p>9.1.2 本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且井不影响本协议其余条款的有效性及可执行性。</p>
            <p>9.1.3 用户与亿岩仓储基于交易合作签署的其他书面协议与本协议不一致的，以双方书面签署的协议为准。</p>
            <p>9.2 存在如下情形，亿岩仓储有权终止本协议：</p>
            <p>（1）用户申请注销账户，亿岩仓储确认的。</p>
            <p>（2）用户违反本协议累计达到3次的；</p>
            <p>（3）其他法律法规规定及亿岩仓储认为需要终止的情形。协议终止后，亿岩仓储有权继续保存您的注冊信息及在亿岩仓储平台上的所有交易信息，并有权视情况删除尚未交易的信息或物品，及末实际履行交易信</p>
            <p>协议终止后，亿岩仓储有权继续保存您的注册信息及在亿岩仓储平台上的所有交易信息，并有权视情况删除尚未交易的信息或物品，及未实际履行交易信息。</p>
        <div class="title">十、法律使用和争议管辖</div>
            <p>本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。</p>
            <p>如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有亿岩仓储住所地(太原市小店区）人民法院提起诉讼。</p>
        <div class="title">十一、其它</div>
            <p>11.1 亿岩仓储尊重用户合法权利，本协议及亿岩仓储上发布的各类规则、声明等其他内容均属不可分割的整体，均是为了更好的、更加便利的为用户提供服务。亿岩仓储欢迎用户和社会各界提出意见和建议，对此亿岩仓储将虛心接受井适时修改本协议及各类规则。</p>
            <p>11.2 知识产权权利人认为其知识产权受到侵害的，可发邮件到haiyunqichen@163.com提交投诉资料，请务必提交真实有效、投诉将无法受理。完整清晰的材料，否则投诉材料包括：a.权属证明；b.举报人的身份证明，身份证明可以是身份证或护照、营业执照等；如果举报人非权利人，请举报人提供代 表权利人进行举报的书面授权证明。c.为确保投诉材料的真实性，在侵权举报中，您还需要签署以下法律声明我为所举报内容的合法权利人；我举报的内容侵犯了本人/公司相应的合法权益；如果本侵权举报内容不完全属实，我将承担由此产生的一切法律责任，井承担和赔偿亿岩仓储因根据投诉人的通知书对相关帐号的处理而造成的任何损失。亿岩仓储有权依据知识产权权利人提供的侵权初步证据，对平台内经营者采取删除、屏蔽、断开链接、终止交易和服务等必要措施。</p>
            <p>11.3 本协议自发布之日起生效。征求意见期间，如您对修改后的协议有任何意见，可联系亿岩仓储(联系方式：haiyunqichen@163.com, 13934568608)。</p>
	</div>
</template>
<script>
export default{
    data(){
        return {
			tableData: [
				{
					name: '登录',
					address: '手机号、验证码、微信授权'
          		},
				  {
					name: '发布',
					address: '联系人姓名、联系电话'
          		},
				  {
					name: '联系购买',
					address: '收获地址、收获人电话、收获人姓名、收获人单位'
          		},
				  {
					name: '名片',
					address: '姓名、职位、公司、手机号'
          		},
		   ],
		   time:null,
        }
    },
    created(){

    },
    mounted() {
		let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
		this.time = year + ' 年 ' + month + ' 月 ' + day + ' 日';
    },
    methods:{
       
    },
}
</script>
<style lang="less" scoped>
.comnent{
	width: 100%;
	height: auto;
	padding: 32px 32px 88px;
	box-sizing: border-box;
	background-color: #fff;

}
@media screen and (min-width:750px){
	.tables{
		width: 50%;
	}
	.comh1{
		width: 100%;
		font-size: 48px;
		line-height: 96px;
		color: #333;
		text-align: center;
		margin-bottom: 56px;
		font-weight: 700;
	}
	span{
		font-size: 28px;
		color: #333;
		line-height: 48px;
	}
	p{
		font-size: 28px;
		color: #333;
		line-height: 48px;
	}
	.title{
		font-size: 32px;
		color: #333;
		line-height: 64px;
		font-weight: 600;
	}
	.time{
		width: 100%;
		font-size: 0.4rem;
		color: #333;
		line-height: 0.7rem;
		float: right;
	}
}
@media screen and (max-width:750px) {

	.tables{
		width: 100%;
	}
	.comh1{
		width: 100%;
		font-size: 0.6rem;
		line-height: 1rem;
		color: #333;
		text-align: center;
		margin-bottom: 1rem;
		font-weight: 700;
	}
	span{
		font-size: 0.4rem;
		color: #333;
		line-height: 0.7rem;
	}
	p{
		font-size: 0.4rem;
		color: #333;
		line-height: 0.7rem;
	}
	.title{
		font-size: 0.5rem;
		color: #333;
		line-height: 1rem;
		font-weight: 600;
	}
	.time{
		width: 100%;
		font-size: 0.4rem;
		color: #333;
		line-height: 0.7rem;
		// float: right;
		margin: 0.4rem 0 0 0;
	}
}

</style>