import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

import NotFont from '../views/NotFont.vue'
import { Message } from 'element-ui'
import privacys from '../views/privacys.vue'
import sergrent from '../views/sergrent.vue'


Vue.use(VueRouter)
// 路由菜单状态：0为显示，1为隐藏，2为显示禁用状态
// 静态路由表
export const staticRouterMap=[
  {
    // 登录首页 
    path: '/Login',
    name: 'Login',
    component: Login 
  },
  {
    // 登录......忘记密码
    path: '/Forget',
    name: 'Forget',
    component:resolve => require(['@/views/Forget.vue'],resolve)
  },
  {
    // 登录......隐私政策
    path: '/Policy',
    name: 'Policy',
    component:resolve => require(['@/views/Policy.vue'],resolve)
  },
  { 
    // 微信app隐私协议
    path: '/privacys',
    name: 'privacys',
    component: privacys
  },
  { 
    // 微信app服务协议
    path: '/sergrent',
    name: 'sergrent',
    component: sergrent
  },
]
// 企业动态路由表
export const activeRouterMap = [
  {
    // Main主页面
    path:'/Main',
    name:'Main',
    meta:{isShow:0,title:'主页'},
    redirect:{name:''},
    component: ()=> import('../views/Main.vue'),
    children:[
      {
        // 首页
        path:'Mindex',
        name:'Mindex',
        meta:{isShow:0,title:'首 页',icon:'el-icon-alishouye'},
        redirect:{name:''},
        component:()=>import('../views/AsidsMain.vue'),
      },
      {
        //设置
        path:'Setup',
        name:'Setup',
        meta:{isShow:0,title:'设 置',icon:'el-icon-alishezhi1'},
        redirect:{name:''},
        component:resolve => require(['@/views/CommonView.vue'],resolve),
       
      },
    ]
  }
]
// 平台动态路由表
export const platformRouterMap = [
  {
    // Main主页面
    path:'/Main',
    name:'Main',
    meta:{isShow:0,title:'主页'},
    redirect:{name:''},
    component: ()=> import('../views/Main.vue'),
    children:[
      {
        //首页
        path:'Mindex',
        name:'Mindex',
        meta:{isShow:0,title:'首 页',icon:'el-icon-alishouye'},
        redirect:{name:''},
        component:()=>import('../views/AsidsMain.vue'),
        children:[
          {
            // 首页......供应链业务.....企业信息
            path:"Qycensus",
            name:"Qycensus",
            meta:{isShow:0,title:'企业信息',icon:'el-icon-alishouye',routeName:'Qycensus'},
            component:resolve => require(['@/views/Ptmain/Qycensus.vue'],resolve)
          }, 
          {
            // 首页......供应链业务.....企业统计......编辑企业统计
            path:"EditQycensus",
            name:"EditQycensus",
            meta:{isShow:1,title:'编辑企业统计',routeName:'Qycensus'},
            component:resolve => require(['@/views/Ptmain/EditQycensus.vue'],resolve)
          },
          {   
            // 首页......熊猫煤仓
            path:'Palmcoal',
            name:'Palmcoal',
            meta:{isShow:0,title:'熊猫煤仓',icon:'el-icon-alimeikuangqiye',routeName:'Palmcoal'},
            component:resolve => require(['@/views/Mindex/MindexView.vue'],resolve),
            children:[
              {
                // 首页......熊猫煤仓.....供货信息
                path:"varietyList",
                name:"varietyList",
                meta:{isShow:0,title:'供货信息',routeName:'varietyList'},
                component:resolve => require(['@/views/Ptmain/varietyList.vue'],resolve)
              },
              {
                // 首页......熊猫煤仓.....采购信息
                path:"procureList",
                name:"procureList",
                meta:{isShow:0,title:'采购信息',routeName:'procureList'},
                component:resolve => require(['@/views/Ptmain/procureList.vue'],resolve)
              },
              {
                // 首页......熊猫煤仓.....云仓管理
                path:"Cloudcloud",
                name:"Cloudcloud",
                meta:{isShow:0,title:'云仓管理',routeName:'Cloudcloud'},
                component:resolve => require(['@/views/Ptmain/Cloudcloud.vue'],resolve)
              },
              {
                // 首页......熊猫煤仓.....用户列表
                path:"userasp",
                name:"userasp",
                meta:{isShow:0,title:'用户列表',routeName:'userasp'},
                component:resolve => require(['@/views/Ptmain/userasp.vue'],resolve)
              },
              {
                // 首页......熊猫煤仓.....意见建议
                path:"advocate",
                name:"advocate",
                meta:{isShow:0,title:'意见建议',routeName:'advocate'},
                component:resolve => require(['@/views/Ptmain/advocate.vue'],resolve)
              },
              // {
              //   // 首页......熊猫煤仓.....标签列表
              //   path:"labeltag",
              //   name:"labeltag",
              //   meta:{isShow:0,title:'标签列表',routeName:'labeltag'},
              //   component:resolve => require(['@/views/Ptmain/labeltag.vue'],resolve)
              // },
              {
                // 首页......熊猫煤仓.....煤种操作
                path:"coaltoper",
                name:"coaltoper",
                meta:{isShow:0,title:'煤种操作',routeName:'coaltoper'},
                component:resolve => require(['@/views/Ptmain/coaltoper.vue'],resolve)
              },
              // {
              //   // 首页......熊猫煤仓.....红包记录
              //   path:"redenvecord",
              //   name:"redenvecord",
              //   meta:{isShow:0,title:'红包记录',routeName:'redenvecord'},
              //   component:resolve => require(['@/views/Ptmain/redenvecord.vue'],resolve)
              // },
              {
                // 首页......熊猫煤仓.....订单记录
                path:"ordergoods",
                name:"ordergoods",
                meta:{isShow:0,title:'订单记录',routeName:'ordergoods'},
                component:resolve => require(['@/views/Ptmain/ordergoods.vue'],resolve)
              },
              
            ]
          },
        ]
      },
      {
        // 设置
        path:'Setup',
        name:'Setup',
        meta:{isShow:0,title:'设 置',icon:'el-icon-alishezhi1'},
        redirect:{name:''},
        component:resolve => require(['@/views/CommonView.vue'],resolve),
        children:[ 
          {
            //设置......平台用户列表
            path:'PtUserList',
            name:'PtUserList',
            meta:{isShow:0,title:'用户列表',icon:'el-icon-aliyonghuliebiao',routeName:'PtUserList'},
            component:resolve => require(['@/views/setup/PtUserList.vue'],resolve)
          },
          {
            //设置......菜单管理
            path:'menuPower',
            name:'menuPower',
            meta:{isShow:0,title:'菜单管理',icon:'el-icon-alifenghuangxiangmutubiao_quanxian',routeName:'menuPower'},
            component:resolve => require(['@/views/setup/menuPower.vue'],resolve)
          },
          {
            //设置......签章配置
            path:'ContractDeploy',
            name:'ContractDeploy',
            meta:{isShow:0,title:'签章配置',icon:'el-icon-aliicon-supplychain',routeName:'ContractDeploy'},
            component:resolve => require(['@/views/setup/ContractDeploy.vue'],resolve)
          },
          {
            //首页......合同配置.....新建合同配置
            path:"AddContractDeploy",
            name:"AddContractDeploy",
            meta:{isShow:1,title:'新建合同配置',routeName:'ContractDeploy'},
            component:resolve => require(['@/views/setup/AddContractDeploy.vue'],resolve)
          },
          {
            //首页......合同配置.....编辑合同配置
            path:"EditContractDeploy",
            name:"EditContractDeploy",
            meta:{isShow:1,title:'编辑合同配置',routeName:'ContractDeploy'},
            component:resolve => require(['@/views/setup/EditContractDeploy.vue'],resolve)
          },
          {
            //设置......平台角色权限
            path:'PtRoleperm',
            name:'PtRoleperm',
            meta:{isShow:0,title:'平台角色权限',icon:'el-icon-alifenghuangxiangmutubiao_quanxian',routeName:'PtRoleperm'},
            component:resolve => require(['@/views/setup/PtRoleperm.vue'],resolve)
          },
          {
            //设置......企业角色权限
            path:'QyRoleperm',
            name:'QyRoleperm',
            meta:{isShow:0,title:'企业角色权限',icon:'el-icon-alifenghuangxiangmutubiao_quanxian',routeName:'QyRoleperm'},
            component:resolve => require(['@/views/setup/QyRoleperm.vue'],resolve)
          },
          {
            //设置......全局配置
            path:'globalConfig',
            name:'globalConfig',
            meta:{isShow:0,title:'全局配置',icon:'el-icon-alifenghuangxiangmutubiao_quanxian',routeName:'globalConfig'},
            component:resolve => require(['@/views/setup/globalConfig.vue'],resolve)
          }
        ]
      },
    ]
  }
]
// 政府动态路由表
export const governmentRouterMap = [
  {
    // 政府看板页面
    path:'/Xzmain',
    name:'Xzmain',
    component: ()=> import('../views/Xzmain.vue'),
  }
]
//错误页路由
export const afterRouteMap=[
  {
    path:'*',
    name:'NotFont',
    component:()=>import('../views/NotFont.vue')
  }
]
//初始化路由表
export const createRouter = () => new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:staticRouterMap
});

// 原始路由表
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:staticRouterMap
})
router.beforeEach((to, from, next) => {
  var Authorization = sessionStorage.getItem('Authorization');
      if(Authorization || to.name =='Login'|| to.name == 'NotFont' || to.name =='Policy' || to.name =='Forget'  || to.name =='privacys' || to.name =='sergrent'){
        next();
      }else{
        Message({
          type:'warning',
          duration:1,
        })
        next({name:'Login'})
      }
})
export default router
